<template>
  <section>
    <p>PANE<strong>SECA</strong></p>
  </section>
  <h1>O que é pane seca?</h1>
  <p>Pane seca é o termo usado para descrever a situação em que um veículo para de funcionar porque acabou o combustível. É uma situação embaraçosa e, muitas vezes, perigosa, especialmente se você estiver em uma rodovia movimentada ou em um local perigoso.</p>
  <h2>O Que é e Como Evitar?</h2>
  <p>Você está dirigindo tranquilamente pela estrada quando, de repente, o seu carro para. Você tenta dar partida novamente, mas nada acontece. Depois de algumas tentativas, você percebe que o seu carro simplesmente ficou sem combustível. Essa situação é conhecida como pane seca e pode acontecer com qualquer um, mas há maneiras de evitá-la.</p>
  <h2>Por que a pane seca acontece?</h2>
  <p>A pane seca geralmente acontece quando o motorista não presta atenção ao nível de combustível no tanque. Quando o combustível acaba, o motor do carro para de funcionar e o veículo não pode mais ser conduzido. Às vezes, a pane seca também pode ser causada por problemas mecânicos, como uma bomba de combustível defeituosa ou um filtro de combustível entupido.</p>
  <h2>Como evitar a pane seca?</h2>
  <p>A melhor maneira de evitar a pane seca é manter o tanque do seu carro sempre com combustível suficiente. Não espere até que o medidor de combustível esteja no limite para abastecer. Se você estiver em uma longa viagem, certifique-se de parar em postos de combustível regularmente para reabastecer.</p>
  <p>Além disso, é importante prestar atenção aos sinais de alerta do seu carro. Se você notar que o seu carro está consumindo mais combustível do que o normal, pode ser um sinal de que há um problema mecânico que precisa ser corrigido. Não ignore esses sinais e leve o seu carro a um mecânico o mais rápido possível.</p>
  <h2>Conclusão</h2>
  <p>A pane seca pode ser evitada com um pouco de cuidado e atenção. Certifique-se de manter o tanque do seu carro sempre com combustível suficiente e preste atenção aos sinais de alerta do seu carro. Se você seguir essas dicas simples, poderá evitar a situação embaraçosa e perigosa da pane seca.</p>
</template>
